// Progress bars

@mixin progress-variant($color) {
  &[value]::-webkit-progress-value {
    background-color: $color;
  }

  &[value]::-moz-progress-bar {
    background-color: $color;
  }

  // IE9
  @media screen and (min-width:0\0) {
    .progress-bar {
      background-color: $color;
    }
  }
}
