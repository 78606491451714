/* =========================================================================

    Project: Transport - Main CSS
    Author: ThemeSun

   ========================================================================= */

/**
 * CONTENTS
 *
 * SETTINGS
 * * Bootstrap
 *
 * BASE
 * * Page
 *
 * OBJECTS
 * * Buttons
 * * Images
 * * Tab Navigation
 * * Custom Heading
 * * Collapse
 * * Slider
 * * Breadcrumb
 * * Widget
 * * Pagination
 * * UI Lists
 * * Tables
 * * UI Quote
 * * Skills Progress Bar
 * * UI Carousel
 * * Sections
 * * Posts Layout
 * * Office
 *
 * COMPONENTS
 * * Page Head
 * * Site Navs
 * * Page Title
 * * Sidebar
 * * Service Layout
 * * About List
 * * About Section
 * * Request
 * * Advisory
 * * Info & News
 * * Testimonials
 * * Image Grid
 * * Posts
 * * Contact
 * * Our Mission
 * * Membars Grid
 * * Product Layout
 * * Product Details
 * * Pricing Tables
 * * Shopping Cart
 * * Extra Pages
 * * Service Brochure
 * * FAQ
 * * Checkout
 * * Page Foot
 * * Feature List
 *
 * TRUMPS
 * * Layout
 * * Hover Effect
 *
 */


/**
 * #SETTINGSs
 */
@import "1-settings/1-settings";
@import "vendor/bootstrap/scss/bootstrap"; //IMPORT Bootstrap v4.0.0-alpha




@import "vendor/bourbon/app/assets/stylesheets/bourbon";
@import "2-tools/2-tools";






@import "3-generic/3-generic";






/**
 * #BASE
 */
@import "4-base/4-base";





/**
 * #OBJECTS
 */
@import "5-objects/5-objects";






/**
 * #COMPONENTS
 */
@import "6-components/6-components";





/**
 * #TRUMPS
 */
@import "7-trumps/7-trumps";
