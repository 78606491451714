/* UI Lists */
.list-group {

    a {
        color: #666;

        @include attention() {
            color: $link-hover-color;
        }

    }

}

.list-group-item {
    display: block;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 600;
    padding: .4375rem 0;

    .sidebar &:first-child {
        padding-top: 0 !important;
    }

    + li {
        border-top: 1px solid #eee;
        padding-top: 10px;
    }

    .label {
        color: #888;
        font-weight: normal;
    }

}

.list-item-icon {

    i {
        font-size: 13px;
        color: $brand-primary;
        margin-right: 5px;
    }

    + .list-item-icon {
        margin-top: 10px;
    }

}

