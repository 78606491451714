.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: $spacer-y;
  margin-bottom: $spacer-y;
  @include border-radius();
}

.page-item {
  display: inline; // Remove list-style and block-level defaults

  &:first-child {
    .page-link {
      margin-left: 0;
      @include border-left-radius($border-radius);
    }
  }
  &:last-child {
    .page-link {
      @include border-right-radius($border-radius);
    }
  }

  &.active .page-link {
    @include plain-hover-focus {
      z-index: 2;
      color: $pagination-active-color;
      cursor: default;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border;
    }
  }

  &.disabled .page-link {
    @include plain-hover-focus {
      color: $pagination-disabled-color;
      cursor: $cursor-disabled;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border;
    }
  }
}

.page-link {
  position: relative;
  float: left; // Collapse white-space
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -1px;
  line-height: $line-height;
  color: $pagination-color;
  text-decoration: none;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  @include hover-focus {
    color: $pagination-hover-color;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border;
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
