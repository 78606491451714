/* Buttons */

.btn {
    font-family: "Oswald", $font-family-sans-serif;
    font-size: 13px;
    letter-spacing: .1em;
}

.btn.btn-primary > i,
.btn.btn-secondary > i {
    margin-left: 10px;
    font-size: 13px;
}

.btn.btn-add-to-cart {
    border: none;
    display: inline-block;
    background-color: #ddd;
    color: #111;
    font-family: $headings-font-family;
    letter-spacing: .1em;
    text-align: center;
    text-transform: uppercase;
    padding: 0 20px;
    height: 46px;
    line-height: 46px;
    position: relative;

    &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 46px 0 0 30px;
        border-color: transparent transparent transparent #dddddd;
        position: absolute;
        top: 0;
        right: -30px;
    }

    @include attention() {
        background: $brand-primary;
        color: #fff;
        &:after {
            border-color: transparent transparent transparent $brand-primary;
        }
    }
}

.btn.btn-add-to-cart-reverse {
    @extend .btn-add-to-cart;
    background: $brand-primary;
    color: #fff;

    &:after {
        border-color: transparent transparent transparent  $brand-primary;
    }

    @include attention() {
        background: #ddd;
        color: #111;

        &:after {
            border-color: transparent transparent transparent #ddd;
        }

    }

}

// Download button
.btn.btn-download {

    padding: 15px 20px;
    font-family: $font-family-base;
    font-size: 14px;
    background: #fff;
    border: 1px solid #ddd;
    color: #111;
    letter-spacing: -.02em;

    .sidebar & {
        width: 100%;
        text-align: left;
    }


    i {
        margin-right: 5px;
        font-size: 18px;
        color: $brand-primary;
    }

}


.rating {
    line-height: 1;
}

.rating .fa-stack {
    font-size: 6px;
}
.rating .fa-star-o {
    color: #999;
    font-size: 14px;
}
.rating .fa-star {
    color: #FFCA28;
    font-size: 14px;
}
.rating .fa-star + .fa-star-o {
    color: #FFCA28;
}
