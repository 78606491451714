/* Posts */
.post {
    margin-bottom: 50px;
}

.post-image {
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-bottom: 35px;
}

.post-image .year,
.post-image .month {
    color: $brand-secondary;
}

.dates {
    display: inline-block;
}

.post-image .dates {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 30px;
    background-color: #fff;
    line-height: 1.2;
}

.post-image .dates:after {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 130px 0 0 50px;
    border-color: transparent transparent transparent #FFFFFF;
}

.post-image .month {
    font-weight: 600;
    display: block;
}

.post-image .date {
    font-weight: 700;
    display: block;
    font-size: 30px;
    color: $brand-primary;
    font-family: $headings-font-family;
}

.post-image .year {
    font-style: italic;
    font-weight: 600;
}


// Post header
.post header {
    margin-bottom: 30px;
}

.post header h3.entry-title {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.3;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    margin-bottom: 30px;
}

.post-details i.fa  {
    padding-left: 10px;
    color: #222231;

    &:first-child {
        padding-left: 0;
    }

}

.post-details {
    color: #666;
}

.post-details a {
    font-weight: 600;
}

.post .post-excerpt {
    color: #858585;
}

.post .entry-footer {

    overflow: hidden;

    >.row {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

}

.post .read-more {
    font-size: 13px;
    font-family: $headings-font-family;
    color: $brand-secondary;
    text-transform: uppercase;
    letter-spacing: .1em;
    @include attention(false) {
        color: $brand-primary;
    }

    & span {
        margin-right: 5px;
        vertical-align: middle;
    }

    i {
        font-size: 13px;
    }
}

.post .entry-footer .read-more {
    color: #222231;
    border-top: 1px solid #ddd;
    padding: 20px 10px 10px 20px;
    display: inline-block;
    position: relative;

    @include attention() {
        color: $link-hover-color;
    }

}

.post .entry-footer .read-more:before {
    content: '';
    position: absolute;
    right: -87px;
    top: 0;
    height: 300px;
    width: 1px;
    background-color: #DDDDDD;
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -ms-transform: skew(30deg);
    -o-transform: skew(30deg);
}

.post .entry-footer .read-more:after {
    content: '';
    position: absolute;
    right: -1029px;
    width: 1000px;
    bottom: 0;
    height: 1px;
    background-color: #DDDDDD;
}

.post .entry-footer .read-more span:after {
    @extend %iconfont;
    content: "\f061";
    margin-left: 10px;
}

.post .entry-footer .share {
    text-align: right;
    color: #666;

    i {
        font-size: 1rem;
    }

    span:first-child i {
        color: #222231;
    }

    a {
        margin-left: 10px;
        color: rgba(35, 35, 49, .4);

        &:hover {
            color: #232331;
        }

    }

}

// FEATURED POST
.featured-post .post-image {
    margin-bottom: 30px;
}

.featured-post header h3.entry-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.featured-post header {
    margin-bottom: 20px;
}

// Single POST
.page-single-post {

    .entry-footer {
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;
    }

    .post-body {
        margin-bottom: 45px;
    }

}

// Comment
/*Comment List styles*/
.comment-list p {
    margin-bottom: 0;
}
.comment-list .row {
    margin-bottom: 0px;
}
.comment-list .panel .panel-heading {
    padding: 4px 15px;
    position: absolute;
    border:none;
    /*Panel-heading border radius*/
    border-top-right-radius:0px;
    top: 1px;
}
.comment-list .panel .panel-heading.right {
    border-right-width: 0px;
    /*Panel-heading border radius*/
    border-top-left-radius:0px;
    right: 16px;
}

.comment-list .panel-default > .panel-heading {
    background: #f5f5f5;
    color: #333;
}
.comment-list .panel .panel-heading .panel-body {
    padding-top: 6px;
}
.comment-list figcaption {
    /*For wrapping text in thumbnail*/
    word-wrap: break-word;
}

.comment-list .panel {
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

.comment-list .panel-body {
    padding: 20px;
}

/* Portrait tablets and medium desktops */
@media (min-width: 768px) {
    .comment-list .arrow:after, .comment-list .arrow:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
    }
    .comment-list .panel.arrow.left:after, .comment-list .panel.arrow.left:before {
        border-left: 0;
    }
    /*****Left Arrow*****/
    /*Outline effect style*/
    .comment-list .panel.arrow.left:before {
        left: 0px;
        top: 30px;
        /*Use boarder color of panel*/
        border-right-color: inherit;
        border-width: 16px;
    }
    /*Background color effect*/
    .comment-list .panel.arrow.left:after {
        left: 1px;
        top: 31px;
        /*Change for different outline color*/
        border-right-color: #FFFFFF;
        border-width: 15px;
    }
    /*****Right Arrow*****/
    /*Outline effect style*/
    .comment-list .panel.arrow.right:before {
        right: -16px;
        top: 30px;
        /*Use boarder color of panel*/
        border-left-color: inherit;
        border-width: 16px;
    }
    /*Background color effect*/
    .comment-list .panel.arrow.right:after {
        right: -14px;
        top: 31px;
        /*Change for different outline color*/
        border-left-color: #FFFFFF;
        border-width: 15px;
    }
}
.comment-list .comment-post {
    margin-top: 6px;
}

.write-comment {
    margin-bottom: 70px;
    margin-top: 50px;
}

// History posts
.history-post {

    .post-image {
        margin-bottom: 20px;
    }
    .post-image .dates {
        padding: 12px 20px;
    }

    .year {
        font-style: normal;
        font-family: $headings-font-family;
        font-weight:normal;
        letter-spacing: .05em;
    }

    &.post header h3.entry-title {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: .05em;
    }

}
