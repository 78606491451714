/* Tables */
.thead-inverse {

    th {
        background-color: $brand-secondary;
        text-transform: uppercase;
        font-family: $headings-font-family;
        letter-spacing: .1em;
        font-weight: normal;
    }

}

.table td,
.table th {
    border-color: #ddd;
    vertical-align: middle;
}

// Root Table Reflow.
.table-reflow {
    thead {
        float: left;
    }

    tbody {
        display: block;
        white-space: nowrap;
    }

    th,
    td {
        border-top: none;
        border-left: none;

        &:last-child {
            border-right: none;
        }
    }

    thead,
    tbody,
    tfoot {
        &:last-child {
            tr:last-child {
                th,
                td {
                    border-bottom: none;
                }
            }
        }
    }

    tr {
        th,
        td {
            display: block !important;
            border: none;
        }
    }
}

.table-shopping-cart {
    label, .radio {
        margin: 0;
    }
}
