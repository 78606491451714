/* Advisory */

.advisory {
    background: #eee;
    padding: 52px 0;
}

.advisory .row  {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.advisory h2 {
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: .05em;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }

}

.advisory.style-2 {
    background: #eeeeee url(../images/advisory/1920x150/bg_01.jpg);
    position: relative;
    overflow: hidden;
    padding: 0;

    @include media-breakpoint-up(lg) {
        &:before {
            content: '' !important;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: .8;
            display: block !important;
            background: $brand-primary;
        }
    }

    .text-wrapper  {
        z-index:1;
        position: relative;
        h2 {color: #fff;}

        &:before {
            content: '';
            background: $brand-secondary;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            left: -900px;
            z-index: -1;
        }
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 230px 0 0 100px;
            border-color: transparent transparent transparent $brand-secondary;
            position: absolute;
            top: 0;
            right: -100px;
        }
    }

    .text-wrapper {
        padding: 65.5px 0;
    }

}
