/* Service Brochure */
.service-brochure {
    background: #eee;

    img {
        margin-bottom: 10px;
    }

    .btn-download {
        width: 49%;
        margin-bottom: 20px;
    }

}
