@function pxtoem($target, $context: 16) { // Context should equal the base font size
    @if $target == 0 { @return 0 }
    @return ($target / $context) + 0em;
}

/* Convert em to px */
@function emtopx($target, $context: 16) { // Context should equal the base font size
    @if $target == 0 { @return 0 }
    @return ($target * $context) + 0px;
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}
