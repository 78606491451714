/* Office */

.office i {
    font-size: 16px;
    width: 35px;
}

.office p {
    .sidebar & {
        color: #666;
        font-weight: 600;
    }
}
