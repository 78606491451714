/* Product Layout */
.product-thumb {
    margin-bottom: 30px;
}
.product-thumb .image {
    text-align: center;
}
.product-thumb .image a {
    display: block;
}
.product-thumb .image a:hover {
    opacity: 0.8;
}
.product-thumb .image img {
    margin-left: auto;
    margin-right: auto;

}
.product-grid .product-thumb .image {
    float: none;
    margin-bottom: 20px;
    // Effect
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-color: $brand-primary;
        border-style: solid;
        border-width: 0;
        -webkit-transition-property: border-width;
        transition-property: border-width;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

}

.product-grid:hover .product-thumb .image:before {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    border-width: 4px;
}
@media (min-width: 767px) {
    .product-list .product-thumb .image {
        float: left;
        padding: 15px;
    }
}
.product-thumb h4 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 20px;
    letter-spacing: .05em;
}
.product-thumb .caption {

}
.product-list .product-thumb .caption {
    margin-left: 230px;
}
@media (max-width: 1200px) {
    .product-grid .product-thumb .caption {
        min-height: 210px;
        padding: 0 10px;
    }
}
@media (max-width: 767px) {
    .product-list .product-thumb .caption {
        min-height: 0;
        margin-left: 0;
        padding: 0 10px;
    }
    .product-grid .product-thumb .caption {
        min-height: 0;
    }
}

.product-thumb .rating {
    padding-bottom: 25px;
}

h2.price {
    margin: 0;
}
.product-thumb .price {
    color: $brand-primary;
    font-family: $headings-font-family;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .05em;
    margin-bottom: 15px;
}
.product-thumb .price-new {
    font-weight: 600;
}
.product-thumb .price-old {
    color: #999;
    text-decoration: line-through;
    margin-left: 10px;
}
.product-thumb .price-tax {
    color: #999;
    font-size: 12px;
    display: block;
}

