 /* Service Layout */

/**
 * With images
 */
.service-img-list {

    padding-top: 70px;
    padding-bottom: 40px;
}

.service-img-list .service-item {
    margin-bottom: 30px;
}

.service-img-list .content {
    padding-top: 30px;

    @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
    }

}

.service-img-list .type {
    float: left;
    display: block;
    margin-top: -140px;
    position: relative;
    z-index: 2;
}

.service-img-list .type i {
    background-color: #fff;
    color: $brand-primary;
    font-size: 40px;
    height: 110px;
    line-height: 110px;
    width: 110px;
    text-align: center;
    position: relative;
    clear: both;
}

.service-img-list .type i:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 130px 0 0 50px;
    border-color: transparent transparent transparent #FFFFFF;
    position: absolute;
    top: 0;
    right: -50px;
}

.service-img-list p {
    color: #888;
    margin-bottom: 20px;
}

.service-img-list h5 {
    font-weight: 700;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
}

/**
 * Without images - Only Icons
 */
.service-icon-list {
    html[class^=page-home] & {padding-top: 70px; }
    padding-bottom: 40px;
}

.service-icon-list p {
    margin-bottom: 0;
}

.service-icon-list .section-heading {
    margin-bottom: 60px;
}

.service-icon-list .content {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
    border: 1px solid #ddd;
    padding: 20px 0;

    &:hover { border-color: $brand-primary; }

    @include media-breakpoint-up(md) {
        text-align: initial;

        &:before {
            content: '' !important;
            display: block !important;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            width: 25%;
            background: #eee;

            @include vendor(clip-path, polygon(0 0, 65% 0, 100% 100%, 0% 100%));
        }

        &:hover {
            .type { color: #fff; }

            &:before {
                background: $brand-primary;
            }

        }

    }

}

.service-icon-list .type {
    font-size: 2.5rem;
    color: $brand-primary;

    @include media-breakpoint-up(md) {
        position: absolute;
        top: 50%;
        left: 50%;

        @include vendor(transform, translateX(-50%));
        .sidebar & {
            transform: translate( -30%,-30%);
            @include vendor(transform, translate(-30%, -30%));
        }

    }

}

.service-icon-list h3 {
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: .05em;
}

.service-icon-grid {
    margin-bottom: 70px;
}

.service-icon-grid.style-3 {
    background: url(../images/services/1920x635/bg-01.jpg);
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, .9);
        z-index: 0;
    }

    .custom-heading {
        z-index: 1;
        position: relative;
    }
}

.service-icon-grid .type {
    font-size: 50px;
    color: $brand-secondary;
    display: block;
    line-height: 1;
    margin-bottom: 30px;
}

.service-icon-grid h3 {
    color: $brand-primary;
}


// Style 2
.service-img-list.style-2 {
    .type i {
        background: $brand-primary;
        color: #fff;
        &:after {
            border-color: transparent transparent transparent $brand-primary;
            border-width: 110px 0 0 50px;
        }
    }
}

