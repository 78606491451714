/* Breadcrumb */

.breadcrumb {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
}

.breadcrumb:before  {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 60px 0 0 40px;
    border-color: transparent transparent transparent transparent;
    border-left-color: $brand-secondary;
    position: absolute;
    top: 0;
    right: -40px;
}

.breadcrumb a {
    color: #A6A6AC;
    &:hover { color: #fff; }
}

.breadcrumb > li {
    float: none;
    display: inline-block;
}

.breadcrumb > li + li::before {
 @extend %iconfont;
}
