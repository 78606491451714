/* Feature List */
.feature-list {

}

.feature-list-item {

}

.feature-list-item + .feature-list-item {
    margin-top: 30px;
}

.feature-list-title {
    font-size: 13px;
}

.feature-list-icon {
    margin-right: 15px;
    font-size: 17px;
}
