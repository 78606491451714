%clearfix {
  @include clearfix;
  &-sm {
    @include media-breakpoint-up(sm) {
      @include clearfix;
    }
  }
  &-md {
    @include media-breakpoint-up(md) {
      @include clearfix;
    }
  }
  &-lg {
    @include media-breakpoint-up(lg) {
      @include clearfix;
    }
  }
}

%iconfont {
  @include font-awesome;
  &-sm {
    @include media-breakpoint-up(sm) {
      @include font-awesome;
    }
  }
  &-md {
    @include media-breakpoint-up(md) {
      @include font-awesome;
    }
  }
  &-lg {
    @include media-breakpoint-up(lg) {
      @include font-awesome;
    }
  }
}

%transit {
  @include transition(all);
  &-sm {
    @include media-breakpoint-up(sm) {
      @include transition(all);
    }
  }
  &-md {
    @include media-breakpoint-up(md) {
      @include transition(all);
    }
  }
  &-lg {
    @include media-breakpoint-up(lg) {
      @include transition(all);
    }
  }
}

%absolute-center {
  @include absolute-center;
  &-sm {
    @include media-breakpoint-up(sm) {
      @include absolute-center();
    }
  }
  &-md {
    @include media-breakpoint-up(md) {
      @include absolute-center();
    }
  }
  &-lg {
    @include media-breakpoint-up(lg) {
      @include absolute-center();
    }
  }
}

%is-translate {
  @include is-translated;
  &-sm {
    @include media-breakpoint-up(sm) {
      @include is-translated();
    }
  }
  &-md {
    @include media-breakpoint-up(md) {
      @include is-translated();
    }
  }
  &-lg {
    @include media-breakpoint-up(lg) {
      @include is-translated();
    }
  }
}
