@if $enable-flex {
  .media {
    display: flex;
    margin-bottom: $spacer;
  }
  .media-body {
    flex: 1;
  }
  .media-middle {
    align-self: center;
  }
  .media-bottom {
    align-self: flex-end;
  }
} @else {
  .media {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }
  .media,
  .media-body {
    overflow: hidden;
    zoom: 1;
  }
  .media-body {
    width: 10000px;
  }
  .media-left,
  .media-right,
  .media-body {
    display: table-cell;
    vertical-align: top;
  }
  .media-middle {
    vertical-align: middle;
  }
  .media-bottom {
    vertical-align: bottom;
  }
}


//
// Images/elements as the media anchor
//

.media-object {
  display: block;

  // Fix collapse in webkit from max-width: 100% and display: table-cell.
  &.img-thumbnail {
    max-width: none;
  }
}


//
// Alignment
//

.media-right {
  padding-left: 10px;
}

.media-left {
  padding-right: 10px;
}


//
// Headings
//

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}


//
// Media list variation
//

.media-list {
  padding-left: 0;
  list-style: none;
}
