/* Product Details */
.product-details {
    margin-bottom: 50px;
}

.product-details h1 {
    font-size: 30px;
    font-weight: 700;
}

.product-details .price {
    font-size: 30px;
    font-weight: 700;
    color: $brand-primary;
}

.rating-comment {

    .comment-count {
        margin-left: 25px;
        color: #666;
        font-weight: 600;
    }

}

.product-details #quantity {
    max-width: 100px;
    display: inline-block;
}
