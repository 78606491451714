/* Image Grid */
.our-clients {
    padding: 70px 0 40px;
}

.our-clients .section-heading {
    margin-bottom: 60px;
}

.our-clients .thumbnail {
    margin-bottom: $grid-gutter-width;
}

.our-clients img {
    background: #eee;
}
