/* Check Out */
.returning-customers {
    a {
        @include attention(true) {
            color: $brand-primary;
            font-weight: 600;
            margin-left: 3px;
        }
    }
}

.ship-different .custom-heading label {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}

.ship-different .custom-heading h2 {
    margin: 0;
}
