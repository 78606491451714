$base-transition-duration: 0.5s !default;
$base-transition-easing: $ease-in-out-cubic !default;

@mixin transition($properties) {
    @if length($properties) > 1 {
        $transition: '';
        @each $property in $properties {
            $transition: $transition + $property + ' ' + $base-transition-duration + ' ' + $base-transition-easing + ',';
        }
        $transition: str-slice($transition, 1, -2);

        transition: unquote($transition);
    } @else {
        transition: unquote($properties) $base-transition-duration $base-transition-easing;
    }
}
