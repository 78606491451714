/* Page Head */
.site-header {
    padding: 20px 0;
    background: $brand-primary;

    @include media-breakpoint-up(lg) {
        background: none;
        padding: initial;

        &.style-2 {
            background: $color-header-home-2;
        }

    }

    &.style-1 {
        overflow: hidden;
    }

    &.style-3 {
        background: #fff;
    }

    &.style-4,
    &.style-7 {
          background: rgba(0, 0, 0, .7);
          position: absolute;
          right: 0;
          left: 0;
          z-index: 2;
    }

    &.style-5 {
         background: none;
    }

    &.style-7 {
        background: rgba(35, 35, 49, .95);
    }

}

.site-branding {

    // Only for style 1.
    header.style-1 & {

        @include media-breakpoint-up(md) {

            &:before {
                content: '';
                background: $color-site-branding-background;
                position: absolute;
                top: 0;
                bottom: 0;
                right: $grid-gutter-width / 2;
                width: 1000px;
                transform: skew(20deg);
                z-index: 1;
            }

        }

    }


    @include media-breakpoint-up(lg) {

        header.style-1 & {
            padding-top: 60px;
            padding-bottom: 60px;
        }

    }

}

    .site-branding img {
        position: relative;
        z-index: 1;
        max-width: 100%;

        // Only for style 1
        header.style-1 & {

            @include  media-breakpoint-up(lg){
                margin-left: -30px;
            }

            @include  media-breakpoint-up(xl){
                margin-left: -60px;
            }

        }

    }

.site-info {
    background: $color-site-info-background;

    header.style-2 &,
    header.style-3 &,
    header.style-4 &,
    header.style-5 &,
    header.style-6 &,
    header.style-7 & {
        background: none;
        padding-top: 35px;
        padding-bottom: 40px;
    }

    header.style-6 & {
        background: $brand-secondary;
    }

    > .container > .row {
        font-size: 0;
        > * {
            font-size: 13px;
            display: inline-block;
            float: none;
            vertical-align: middle;
        }
    }

}

.contact-bar {

}

.contact-detail {

    header.style-1 & {
        padding-top: 35px;
    }

    h3 {
        font-size: 1.125rem;
        color: $color-contact-heading;
        header.style-2 &,
        header.style-6 & {
            color: $color-contact-heading-style-2;
        }
    }

    i {
        display: inline-block;
        font-size: 30px;
        margin-bottom: 10px;
        float: left;
        margin-right: 15px;
        color: $brand-primary;
    }

    span {
        font-weight: 600;
        color: #777;
    }
}

// SEARCH
.search-box {
    text-align: right;
    display: inline-block;

    .fa {
        font-size: 16px;
        color: #232331;

        header.style-2 &,
        header.style-4 &,
        header.style-6 &,
        header.style-7 &{
            color: #fff;
        }

    }

    header.style-1 & {
        padding-top: 45px;
    }


    header.style-4 &,
    header.style-5 &,
    header.style-6 &,
    header.style-7 & {
        padding-top: 1.5rem;
    }

}

#search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);

    z-index: 9;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    -webkit-transform: translate(0px, -100%) scale(0, 0);
    -moz-transform: translate(0px, -100%) scale(0, 0);
    -o-transform: translate(0px, -100%) scale(0, 0);
    -ms-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);

    opacity: 0;
}

#search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
    -o-transform: translate(0px, 0px) scale(1, 1);
    -ms-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
}

#search input[type="search"] {
    position: absolute;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0);
    font-size: 60px;
    font-weight: 300;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    box-sizing: border-box;
}
#search .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 61px;
    margin-left: -45px;
    background-color: $brand-primary;
    border: black;

}
#search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    background-color: $brand-primary;
    border-color: green;
    opacity: 1;
    padding: 10px 17px;
    font-size: 27px;
}

//-----------------------------
//  Header 02
//-----------------------------

