/* Section */

.page-ground,
.page-cargo,
.page-ware-housing,
.page-logistic,
.page-trucking-service,
.page-storage {

    main section {
        margin-bottom: 40px;
    }


}

.highlight-part {
    padding: 40px 30px;
}

.download-section   {
    padding: 50px 35px;
    background: #eee;
    border-top: 1px solid #ddd;
    vertical-align: middle;

    h2 {
        vertical-align: middle;
        font-size: 18px;
        letter-spacing: .05em;
        margin:10px 0;
    }

}

.home-section {
    margin: 0 !important;
    padding: 70px 0;
}
