 /* Page Foot */

.site-footer {
    padding: 70px 0px 40px 0px;
    background: $brand-secondary;
    color: #aaa;
}

.site-footer {
    ol, ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.site-footer p {

}

.site-footer .widget img {
    margin: -15px 0 20px;
}

.site-footer .widget-title {
    color: #fff;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .05em;
}

.site-footer .widget-title:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.site-footer .widget-title span {
    background-color: $brand-primary;
}

.site-footer .widget-title span {
    position: relative;
    display: inline-block;
    padding: 10px 10px 10px 20px;
}

.site-footer .widget-title span:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 0 0 50px;
    border-color: transparent transparent transparent transparent;
    border-left-color: $brand-primary;
    position: absolute;
    top: 0;
    right: -50px;
}

.site-footer .social-menu {
    text-align: initial;

    .fa {
        font-size: 20px;
        color: rgba(255, 255, 255, .6);

        &:hover {
            color: $brand-primary;
        }
    }

    .menu-item {
        margin-left: 10px;
    }

}

.site-footer #menu-information li {
    margin-bottom: 10px;
}

.site-footer #menu-information a {
    color: #B9BFC5;
    font-weight: 600;

    @include attention() {
        text-decoration: none;
        color: $brand-primary;
    }

}

.site-footer #menu-information li a:before {
    @include font-awesome;
    content: "\f178";
    margin-right: 10px;
}

@include media-breakpoint-up(lg) {

    .site-footer #menu-information {
        display: flex;
        flex-wrap: wrap;
    }

    .site-footer #menu-information li {
        flex: 0 0 41.6666666667%;
    }

}

// Copyright
.copyright {

    color: #fff;
    background: $brand-secondary;

    .left, .right {
        padding-top: 40px;
        padding-bottom: 40px;
    }

}

.copyright a {
    color: #fff;
}


.copyright .left {
    background: $brand-primary;
}

.copyright .left:before {
    background: $brand-primary;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1000px;
    width: 1000px;
}

@include media-breakpoint-down(sm) {
    .copyright .left:after {
        background: $brand-primary;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1000px;
        width: 1000px;
    }
}

@include media-breakpoint-up(md) {

    .copyright .left:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 200px 0 0 90px;
        border-color: transparent transparent transparent transparent;
        border-left-color: $brand-primary;
        position: absolute;
        top: 0;
        right: -90px;
    }

}
.copyright .right {
    text-align: right;
    color: #aaa;
}
