/* Request */
.request {
    text-align: center;
    overflow: hidden;
    @include media-breakpoint-down(md) {
        background: $brand-secondary;
    }

    &.style-2 {
        .request-content {
            background: $brand-primary;
        }

        p {
            color: #fff;
        }

        .custom-heading {
            &:before,
            &:after {
                color: #fff;
            }
        }

        .request-form input,
        .request-form textarea {
            background: #fff;
            color: #888;
        }

        @include media-breakpoint-only(xl) {
            .request-content:before {
                border-right-color: $brand-primary;
            }

            .request-content:after {
                background: $brand-primary;
            }
        }
    }
}


.request .request-content {
    padding-top: 90px;
    padding-bottom: 75px;
    background: $brand-secondary;
}

    @include media-breakpoint-only(xl) {
        .request .request-content:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 300px 700px 0;
            border-color: transparent;
            border-right-color: $brand-secondary;
            position: absolute;
            top: 0;
            left: -300px;
        }

        .request .request-content:after {
            content: '';
            background: $brand-secondary;
            position: absolute;
            right: -800px;
            width: 800px;
            top: 0;
            bottom: 0;
        }
    }

.request .part-heading > h2 {
    color: #fff;
    margin-bottom: 40px;
}

.request p {
    margin-bottom: 30px;
    color: #aaa;
    letter-spacing: -.02em;
}

.request-form {

    input, textarea {
        margin-bottom: 10px;
        background-color: #65656F;
        color: #FFFFFF;
        border: none;

        &::placeholder {
            color: #fff;
        }

        .request.style-2 & {
            &::placeholder {
                color: #888;
            }
        }
        &:focus {
            background-color: #fff;
            color: #888;

            &::placeholder {
                color: #888;
            }

        }

    }

    input[type="submit"] {
        margin-bottom: 0;
        background: $brand-primary;
    }

    .request.style-2 & {
        input[type="submit"] {
            color: $brand-primary;
        }
    }

    @include media-breakpoint-up(lg) {
        [class*='col-'] {
            padding: 0 5px;
        }
    }

}
