/* Pagination */

.pagination {

}

.pagination .page-link {
    margin-left: 5px;
    height: 48px;
    width: 48px;
    line-height:48px;
    padding: 0;
    text-align: center;
    font-size:13px;
    font-family: $headings-font-family;
    letter-spacing: .1em;
}

.pagination .page-link i {
    font-size: 13px;
}

.pagination .nav-text {
    border: none;
    width: auto;
    height: auto;

    &.nav-text-next {
        margin-left: 15px;
    }

    &.nav-text-prev {
        margin-right: 15px;
    }

    @include attention() {
        background: none;
    }

}
