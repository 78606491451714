/* Layout */
.xs-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
}
.xs-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
}
.xs-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
}
@include media-breakpoint-up(sm) {
    .sm-start {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }
    .sm-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }
    .sm-end {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }
}
@include media-breakpoint-up(md) {
    .md-start {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }
    .md-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }
    .md-end {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }
}
@include media-breakpoint-up(lg) {
    .lg-start {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }
    .lg-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }
    .lg-end {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }
}
@include media-breakpoint-up(xl) {
    .xl-start {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }
    .xl-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }
    .xl-end {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }
}



