/* Slider */
#myCarousel {

}

#myCarousel .carousel-caption {

    text-align: center;

    @include media-breakpoint-up(xl) {
        bottom: 20%;
    }

    h1 { color: #fff;}

}

#myCarousel .carousel-caption h1 {

    @include media-breakpoint-up(xl) {
        font-size: 70px;
        font-weight: 700;
    }

}

.carousel-indicators.left-bottom-indicators {
    left: 0;
    bottom: 0;
    margin-left: 0;
    width: auto;
    margin-bottom: 0;
    display: inline-block;

    padding: 15px 20px 10px;
    background: #fff;

    li {
        border: 1px solid #808080;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin: 1px;
    }

    .active {
        background: #808080;
    }

    &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 130px 0 0 50px;
        border-color: transparent transparent transparent #FFFFFF;
        position: absolute;
        top: 0;
        right: -50px;
    }

}

.slide {
    overflow: hidden;
    z-index: 1;
}


