/* Navigation Tabs */

.nav-tabs .nav-link {
    background: $brand-secondary;
    color: $body-bg;
}

.nav-tabs .nav-item.active .nav-link {
    @extend .nav-link.active;
}

.nav-tabs + .tab-content {
    padding: 35px 0;
    @include clearfix;
}
