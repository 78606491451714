/* Contact */
#map-canvas {
    margin-bottom: 70px;
}

.info-contact {

    .part-heading {
        text-align: left;
        margin-bottom: 30px;
    }

    & p {
        color: #858585;
    }

}

.info-contact .office {
    color: #666;
    font-weight: 600;

    @include media-breakpoint-down(md) {
        margin-bottom: 70px;
    }

}

.contact-form input,
.contact-form textarea {
    margin-bottom: $grid-gutter-width;
}
