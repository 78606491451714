// Brand Secondary Color
$brand-secondary:                       #232331;

// Page head
$color-site-branding-background:        $brand-primary;
$color-site-info-background:            #fff;
$color-contact-heading:                 #111;

// Main Navigation
$color-main-navbar-background:          $brand-secondary;
$color-hover-nav-link:                  rgba(255,255,255,0.2);

// Home Style 2
$color-top-menu-bar-home-2:             rgba(255,255,255, .05);
$color-header-home-2:                   #232331;

$link-color-top-menu-style-2:           #fff;

$color-contact-heading-style-2:         #fff;

// Home Style 4
$link-color-top-menu-style-4:           $link-color-top-menu-style-2;

