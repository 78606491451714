/* Sidebar */
.sidebar {
    overflow: hidden;
}

.sidebar .widget {
    margin-bottom: 40px;
}

.sidebar .widget-title {
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
    font-size: 24px;
    letter-spacing: 0.05em;
    font-weight: 700;
    margin-bottom: 30px;
    color: #111;
}

.sidebar .widget-title:before {
    content: "\\\\\\\\";
    color: $brand-primary;
    font-size: 33px;
    letter-spacing: -5px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
    margin-top: -4px;
    line-height: 1;
    font-weight: bold;
    font-family: "Times New Roman", "Bitstream Charter", Times, serif;
}

.sidebar .widget_search {


}

.sidebar .widget_search .search-submit {
    height: 48px;
    position: relative;
    color: $brand-secondary;
    background: #eee;
    outline: none;
    border: 1px solid #ddd;

    & i {
        font-size: 1rem;
    }

    &:before {
        content: '';
        position: absolute;
        left: -20px;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 48px 0;
        border-color: transparent #eee transparent transparent;
    }

}

.sidebar .widget_search input[type="search"] {
    border: 1px solid #ddd;
    background-color: #fff;
    height: 48px;
    margin-bottom: 0;
}

.sidebar .widget .recent-posts-item {

    word-wrap: break-word;
    @extend %clearfix;

    & + .recent-posts-item {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #EEEEEE;
    }

    a {
        font-size: 14px;
        letter-spacing: .05em;
        font-family: $headings-font-family;
    }

}

.sidebar .widget .recent-posts-thumb {
    float: left;
    margin-right: 20px;
    position: relative;
}

.sidebar .widget-product-list {

    .list-group-item {
        padding: 1rem 0;
        @include clearfix;
        a {
            color: $brand-secondary;
            @include attention(false) {
                color: $brand-primary;
            }
        }
    }

    img {
        max-width: 70px;
        float: left;
        margin-right: 10px;
    }

    .product-title {
        font-family: $headings-font-family;
        font-weight: normal;
        letter-spacing: .05em;
    }

    .amount {
        color: $brand-primary;
        font-size: 13px;
        font-weight: 700;
        font-family: $headings-font-family;
    }

}
