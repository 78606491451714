/* Members Grid */
.members-grid {
    margin-bottom: 70px;

    .home-section & {
        margin: 0;
    }
}

.members-grid img {
    margin-bottom: 30px;
}

.members-grid h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .05em;
}

.members-grid h5 {
    margin-bottom: 15px;
    font-family:  $font-family-base;
    font-style: italic;
    font-size: 14px;
    font-weight: 500;
}
