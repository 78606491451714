/* Extra Pages */
.intro {
    height: 100vh;
    width: 100%;
    display: table;
    position: relative;
}

.intro:before,
.intro:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    z-index: -1;

    -webkit-transform: skew(30deg, 0deg);
    -ms-transform: skew(30deg, 0deg);;
    transform: skew(30deg, 0deg);;

}

.intro:before {
    top: 0;
    left: -50%;
    background: #F7F7F7;
}

.intro:after {
    top: 0;
    right: -50%;
    background: #eee;
    z-index: -2;

}

.intro-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.intro-content {

    .custom-heading,
    .description {
        margin-bottom: 40px;
    }

}

// MAINTANANCE MODE
.maintanance {
    @extend .intro;
    background: url("images/maintanance/maintanance-bg.jpg");

    &:after,
    &:before {
        display: none;
    }

}

.maintanance-content {
    @extend  .intro-content;
}

.maintanance-icon-grid {
    @extend .service-icon-grid;
    text-align: left;
    margin-bottom: 50px;

    .custom-heading {
        margin: 0;
    }

    ul > li > i {
        margin-right: 10px;
    }
}

.not-found {
    margin-bottom: 70px;

    i.not-found-icon {
        font-size: 200px;
        margin-bottom: 50px;
        color: $brand-primary;
    }

    h1 {
        font-size: 36px;
    }

    .search-submit {
        height: 48px;
        position: relative;
        color: #232331;
        background: #eee;
        outline: none;
        border: 1px solid #ddd;


        &:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 48px 0;
            border-color: transparent #eee transparent transparent;
        }
    }
}
