/* Site Navs */
.menu-item, .page_item {
    display : inline-block;
}




// Top menu bar
.top-menu-bar {
    position: relative;

    header.style-2 & {
        background: $color-top-menu-bar-home-2;
    }

    header.style-4 &,
    header.style-7 &
    {
        &:before {
            background-color: rgba(255, 255, 255, .2);
        }
    }

    header.style-5 & {
        background: $brand-secondary;
    }
    header.style-6 & {
        background: $brand-primary;
    }


    a {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        color: #777777;

        @include attention() {
            color: $link-color;
        }

        header.style-2 & {
            color: #aaa;
            @include attention() {
                color: $link-color-top-menu-style-2;
            }
        }

        header.style-4 &,
        header.style-5 &,
        header.style-7 & {
            color: #aaa;
            @include attention() {
                color: $link-color-top-menu-style-4;
            }
        }

        header.style-6 & {
            color: #fff;
            @include attention() {
                color: $link-color-top-menu-style-4;
            }
        }

    }

    &:before {
        content: '';
        position: absolute;
        left: -900px;
        right: -900px;
        height: 1px;
        bottom: 0;
        background-color: #dddddd;

        header.style-2 &,
        header.style-6 &{
            display: none;
        }

    }

    ul {
        padding: 0;
        margin: 0;
    }

    .social-menu {

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: block;
            border-left: 1px solid #DDDDDD;
            border-right: 1px solid #DDDDDD;
            @include vendor(transform, skew(20deg));

            header.style-2 &,
            header.style-5 &,
            header.style-6 &{
                display: none;
            }

            header.style-4 &,
            header.style-7 & {
                border-left: 1px solid rgba(255, 255, 255, .2);
                border-right: 1px solid rgba(255, 255, 255, .2);
            }

        }



    }

}

// Top menu
.top-menu {
    position: relative;
    z-index: 1;

    .menu-item {
        margin-right: 15px;

        a {
            line-height: 50px;
            display: block;
        }

        a:after {
            @include font-awesome;
            content: "\f178";
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;

            .sub-menu {
                opacity: 1;
            }

        }
    }

    .sub-menu {
        position: absolute;
        line-height: 1;
        background-color: #fff;
        left: 0;
        width: 120px;
        opacity: 0;
        @extend %transit;

        .menu-item {
            padding: 5px 10px;
            border-bottom: 1px solid #eeeeee;
            display: block;

            a {
                font-size: 12px;
                color: #111;
                line-height: 1;

                &:after {
                    display: none;
                }

            }

        }

    }

}





// Social menu
.social-menu {
    text-align: center;

    .menu {

        li {

            display: inline-block;
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }

            &:hover {

            }

            &:before {
                display: none;
            }

            a {
                line-height: 50px;
                z-index: 1;
                position: relative;
            }

        }

    }

}





// Primary menu - Remake Bootstrap menu.
// Mobile
/*--------------------------------------------
Mobile Menu
----------------------------------------------*/
.site-header .trigger-menu {
    background: center center no-repeat transparent;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADhJREFUeNpi/P//PwOtARMDHQBdLGFBYtMq3BiHT3DRPU4YR4NrNAmPJuHRJDyahEeT8Ii3BCDAAF0WBj5Er5idAAAAAElFTkSuQmCC');

    display: block;
    width: 40px;
    height: 40px;
    //position: absolute;
    //top: 0;
    //left: 10px;
}

.site-header .contact-bar .trigger-menu {
    position: static;
    text-align: right;
    margin-top: 35px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAYCAMAAADJYP15AAAAHlBMVEUAAAAjIzEjIzEjIzEjIzEjIzEjIzEjIzEjIzEjIzEfUrJoAAAACXRSTlMAIDBAcICwwPCKu9t/AAAAPUlEQVR42s2PMRIAIAjDABHx/x92YameXTVjhvQqb/AOeOm5UToGEKW1Acoip2aRc/IS4XcM28YjiTblJxb1qQVdUwTzOgAAAABJRU5ErkJggg==);

}

.site-header.style-2 .trigger-menu,
.site-header.style-3 .trigger-menu {
    @include media-breakpoint-up(lg) {
        position: static;
        text-align: right;
        margin-top: 35px;
    }
}

.site-header.style-3 .trigger-menu {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAYCAMAAADJYP15AAAAHlBMVEUAAAAjIzEjIzEjIzEjIzEjIzEjIzEjIzEjIzEjIzEfUrJoAAAACXRSTlMAIDBAcICwwPCKu9t/AAAAPUlEQVR42s2PMRIAIAjDABHx/x92YameXTVjhvQqb/AOeOm5UToGEKW1Acoip2aRc/IS4XcM28YjiTblJxb1qQVdUwTzOgAAAABJRU5ErkJggg==);
}



// Desktop
.bg-dark {
    background: $color-main-navbar-background;
}

.bg-primary-color {
    background: $brand-primary;
}

// Custom nav for custom home
.navbar {
    font-size: 13px;
    .page-home_2 & {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
    }

    &.style-4 .nav-item + .nav-item > a,
    &.style-5 .nav-item + .nav-item > a,
    &.style-7 .nav-item + .nav-item > a {

        position: relative;

        &:before {
            content: '\\\\';
            position: absolute;
            left: 0;
            font-family: "Times New Roman", "Bitstream Charter", Times, serif;
            color: rgba(221, 221, 211, .2);
            font-size: 15px;
        }
    }
    &.style-5 .nav-item + .nav-item a:before {
        color: #ddd;
    }
}

.navbar-nav {

    li.dropdown:hover .dropdown-menu { display:block };
    .dropdown-menu { margin-top: 0 };

    .nav-item > a,
    .dropdown-item > a {
        font-family: $headings-font-family;
        letter-spacing: .1em;
        font-size: 13px;
    }

    .dropdown-item > a {
        font-size: 11px;
        font-weight: 400;
    }

    .dropdown-item:hover {
        &:after {
            background: $brand-primary;
        }
    }

    .nav-link {
        display: block;
        padding: 1.5rem;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 100%;
            top: 49%;
            left: 50%;
            opacity: 0;
            @extend %transit;
            @include vendor(transform, translateX(-50%) translateY(-49%) skewX(20deg));
            z-index: 1;
            background: $color-hover-nav-link;

        }

        + .nav-link {
            margin-left: 1rem;
        }
    }

    .nav-item:hover .nav-link:after {
        opacity: 1;
        width: 100%;
    }



    .nav-item + .nav-item {
        margin-left: 0;
    }

    .dropdown-menu {
        background: none;
        border: none;
        outline: 0;
        &:hover {
            background: none;
        }
        &:after {
            display: none;
        }
    }

    .dropdown-item {
        padding: 0;
        position: relative;
        display: inline-block;
        width: auto;

        a {
            color: #fff;
            padding: 20px;
            display: inline-block;
        }
        &:after {
            background: $brand-secondary;
            content: '';
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            -webkit-transform: skew(20deg);
            -moz-transform: skew(20deg);
            -ms-transform: skew(20deg);
            -o-transform: skew(20deg);
            z-index: -1;
        }
        &:hover {
            background: none;
        }
    }

}

.navbar.style-4 .nav-link:after,
.navbar.style-5 .nav-link:after,
.navbar.style-7 .nav-link:after {
    @include vendor(transform, translateX(0) translateY(0) skewX(0));
    height: 2px;
    top: 80%;
    left: 0;
    background: $brand-primary;
}

.navbar.style-4 .nav-link:hover,
.navbar.style-5 .nav-link:hover {
    color: $brand-primary;
}
.navbar.style-5 .active .nav-link,
.navbar.style-5 .active .nav-link:hover {
    color: $brand-primary;
}

.navbar.style-5 .nav-link,
.navbar.style-6 .nav-link {
    color: $brand-secondary;
}

.navbar.style-6 .nav-link:after {
    background: $brand-primary;
    z-index:-1;
}

.navbar.style-6 .nav-item.active .nav-link {
    color: $brand-secondary;
}

// MINI CART
.cart {
    padding: 1.5rem 0;
    cursor: pointer;
    line-height: 1;

    .navbar & {
        color: #fff;
    }
    .navbar.style-6 & {
        color: $brand-secondary;
    }

    .navbar-nav & {
        color: $navbar-dark-color;
    }

    i {
        font-size: 19px;
        line-height: 1;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-menu {
        left: auto;
        right: 0;
        z-index: 1001;
        padding: 50px 20px;
        min-width: 300px;
    }

    .search-box + & {
        display: inline-block;
        padding: 0;
        margin-left: 25px;
        color: #fff;

        .style-5 & {
            color: #000;
        }
    }

}
//-----------------------------
//  Mega Styles
//-----------------------------

.mega {

    // For Bootstrap 4.
    .navbar-nav {


    }

    // reset positions
    .nav, .collapse, .dropup, .dropdown {
        position: static;
    }

    // propagate menu position under container for fw navbars
    .container {
        position: relative;
        @extend %clearfix;
    }

    // by default aligns menu to left
    .dropdown-menu {
        left: auto;
    }

    // Content with padding
    .mega-content {
        padding: 20px 60px;
        position: relative;
        color: #fff;
        a {
            color: #fff;
        }
        &:after {
            background: $brand-secondary;
            content: '';
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            -webkit-transform: skew(20deg, 0);
            -moz-transform: skew(20deg, 0);
            -ms-transform: skew(20deg, 0);
            -o-transform: skew(20deg, 0);
            z-index: -1;
            display: block;
        }
    }

    .mega-menu-img {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    // Fullwidth menu
    .dropdown.mega-fw .dropdown-menu {
        left: 0; right: 0;
    }

}

//-----------------------------
//  SERVICES MENU
//-----------------------------
.service-menu.service-icon-list {

    padding: 0;

    h3 {
        margin: 0;
        font-size: 14px;
    }

    .type {
        font-size: 24px;
    }

    .content {
        margin-bottom: 10px;

    }

    .active {
        .content {
            border-color: $brand-primary;
            &:before {
                background: $brand-primary;
            }
        }

        .type {
            color: #fff;
        }

    }

    > div:last-child {
        margin-bottom: 0;
    }

}

