/* Page Title */
.page-title {
    position: relative;
    margin-bottom: 70px;
    background-position: center center;
    overflow: hidden;
}

.page-title:after {
    content: '';
    background-color: rgba(238,238,238,0.9);
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
}

.page-title .container {
    position: relative;
    z-index: 1;
}

.page-title .entry-title {
    font-family: Oswald;
    font-weight: 700;
    letter-spacing: 0em;
    color: #111111;
    font-size: 48px;
    padding: 90px 0px 60px 0px;
}

@each $page in contact, our-services {

    .page-#{$page} .page-title {
        margin-bottom: 0;
    }

}


