/* Info & News */
.info-news {
    padding: 70px 0;
}

.info-news .part-heading {
    margin-bottom: 60px;
}

.why-us {
    margin-bottom: 70px;

    @include media-breakpoint-up(lg) {
        margin-bottom: initial;
    }

}

// LATEST NEWS
.latest-news {


}

.latest-news .thumb {
    float: left;
    margin-right: 20px;
    position: relative;
}

.latest-news .item {
    @include clearfix;

    & +.item {
        margin-top: 20px;
    }
}

.latest-news h3 {
    font-size: 15px;
}

.latest-news .post-meta {

    span {
        color: #878787;

        & + span {
            margin-left: 10px;
        }

    }

}

// Blog grid  - Home section
.blog-grid {

}
